// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/

:root {
  --ion-border-radius: 5px;
  --ion-tab-spacing: 140px;

  --ion-font-family: ApaxThree;
  --ion-default-font-family: ApaxThree;
  --ion-content-font-family: ApaxThree;

  --ion-line-height-icon-override: inherit;
  --ion-line-height-min: inherit;
  --ion-line-height-min-mid: inherit;
  --ion-line-height-mid: inherit;
  --ion-line-height-mid-max: 28.8px;
  --ion-line-height-max: inherit;
  --ion-line-height-max-override: inherit;

  --text-dark: #141414;
  --text-light: #9e9e9e;
  --ion-color-step-300: transparent;
  --ion-font-size-intro-heading: 8.8vw;
  --ion-font-size-intro-body: 3.2vh;
  --ion-default-font-size: 14pt;
  --ion-extra-small-font-size: calc(var(--ion-default-font-size) * 0.6);
  --ion-small-font-size: calc(var(--ion-default-font-size) * 0.8);
  --ion-medium-font-size: calc(var(--ion-default-font-size) * 1);
  --ion-large-font-size: calc(var(--ion-default-font-size) * 1.2);
  --ion-extra-large-font-size: calc(var(--ion-default-font-size) * 1.4);
  --ion-extra-extra-large-font-size: calc(var(--ion-default-font-size) * 1.6);
  --ion-client-height-override: 0px;

  /** primary **/
  --ion-color-primary: #86c8ce;
  --ion-color-primary-rgb: #86c8ce;
  --ion-color-primary-bright: #a6f8ff;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: #ffffff;
  --ion-color-primary-shade: #7ac4b9;
  --ion-color-primary-tint: #86c8ce;

  /** secondary **/
  --ion-color-secondary: #52857d;
  --ion-color-secondary-rgb: #52857d;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: #ffffff;
  --ion-color-secondary-shade: #7ac4b9;
  --ion-color-secondary-tint: #72f3ff;
  --ion-color-secondary-light: #a4f7ff;

  /** tertiary **/
  --ion-color-tertiary: #dbedf8;
  --ion-color-tertiary-rgb: #dbedf8;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: #ffffff;
  --ion-color-tertiary-shade: #dbedf8bd;
  --ion-color-tertiary-tint: #addaff65;
  --ion-color-tertiary-fifty: #addaff63;

  /** tertiary **/
  --ion-color-alternate: #dbedf8;
  --ion-color-alternate-hover: #a9deff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: #2dd36f;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: #ffffff;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: #ffc409;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: #000000;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: #eb445a;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: #ffffff;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #111;
  --ion-color-dark-rgb: #111;
  --ion-color-dark-contrast: #b5b5b5;
  --ion-color-dark-contrast-rgb: #b5b5b5;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** alpha  values **/
  --ion-color-dark-alpha: #64646480;

  /** medium **/
  --ion-color-medium: #222;
  --ion-color-medium-rgb: #222;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: #ffffff;
  --ion-color-medium-shade: #585858;
  --ion-color-medium-tint: #00000056;

  /** light **/
  --ion-color-light: #b5b5b5;
  --ion-color-light-rgb: #b5b5b5;
  --ion-color-light-contrast: #646464;
  --ion-color-light-contrast-rgb: #646464;
  --ion-color-light-shade: #9f9f9f;
  --ion-color-light-tint: #bcbcbc;

  /** lighter **/
  --ion-color-lighter: #f4f5f8;
  --ion-color-lighter-rgb: #f4f5f8;
  --ion-color-lighter-contrast: #646464;
  --ion-color-lighter-contrast-rgb: #646464;
  --ion-color-lighter-shade: #d7d8da;
  --ion-color-lighter-tint: #f5f6f9;

  /** lightest **/
  --ion-color-lighter: #efefef;

  /** background color **/
  --ion-color-background: #ffffff;
  --ion-color-background-rgb: 255, 255, 255;
  --ion-color-background-contrast: #000000;
  --ion-color-background-contrast-rgb: 0, 0, 0;
  --ion-color-background-shade: #e0e0e0;
  --ion-color-background-tint: #ffffff;
}
html.web :host {
  font-family: var(--ion-font-family);
}
body {
  font-family: var(--ion-font-family) !important;
}
a {
  text-decoration: none;
}
.margin-btm-100 {
  margin-bottom: 100px;
}

/* Button styles */

ion-button.primary-btn {
  --border-radius: 5px !important;
  --box-shadow: none;
  --background: var(--ion-color-primary) !important;
  --color: var(--ion-color-primary-contrast) !important;
  --background-hover: var(--ion-color-primary-bright);
  font-weight: 300 !important;
}

ion-button.secondary-btn {
  --border-radius: 5px !important;
  --box-shadow: none;
  --background: var(--ion-color-alternate) !important;
  --color: var(--ion-color-dark) !important;
  --background-hover: var(--ion-color-primary);
  font-weight: 300 !important;
}
.button-solid {
  --box-shadow: none;
}
.main-heading {
  --color: var(--ion-color-dark) !important;
}
.secondary-btn {
  border-radius: 5px !important;
  box-shadow: none;
  background: var(--ion-color-alternate) !important;
  color: var(--ion-color-dark) !important;
  --background-hover: var(--ion-color-primary);
  font-weight: 300 !important;
  min-width: 6rem;
  outline: none;
  border: none;
}
.secondary-btn:hover {
  background: var(--ion-color-primary);
}

.primary-btn {
  border-radius: 5px !important;
  box-shadow: none;
  background: var(--ion-color-primary) !important;
  color: var(--ion-color-primary-contrast) !important;
  font-weight: 300 !important;
  min-width: 6rem;
  outline: none;
  border: none;
}
.primary-btn:hover {
  background: var(--ion-color-primary-bright);
}
.primary-btn:focus,
.secondary-btn:focus {
  outline: none;
  box-shadow: 0 !important;
}
.primary-btn,
.secondary-btn {
  margin-bottom: 2rem;
}

ion-button.searchButton {
  padding: 0px;
  margin: 0px;
  border-radius: 0px;

  height: 45px;
  width: 45px;
}

/* Sweet alert styles */
.swal-title,
.swal-timeText {
  color: var(--ion-color-primary);
}
.swal-title {
  font-size: large;
  font-weight: 400;
}
.swal-timeText {
  color: var(--ion-color-primary);
  font-size: large;
  font-weight: 300;
  margin-bottom: 2rem;
}
div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm:focus {
  box-shadow: 0 0 0 0px rgba(112, 102, 224, 0) !important;
}

.time-icon {
  font-size: 6rem;
  color: var(--ion-color-secondary);
}

.textarea-wrapper.sc-ion-textarea-md {
  --border-color: var(--ion-color-lighter-shade);
  --border-radius: 10px;
  --padding-start: 36px;
  --padding-end: 36px;
  min-height: 40px;
}

.med-input .mat-form-field-label-wrapper {
  pointer-events: all;
  cursor: pointer;
}
.med-input .myInput {
  border-top: 0px solid var(--ion-color-lighter-shade);
  border-right: 0px solid var(--ion-color-lighter-shade);
  border-bottom: 0px solid var(--ion-color-dark-contrast-rgb);
  border-left: 0px solid var(--ion-color-lighter-shade);
  border-radius: 0px;
}
.border-left-grey {
  border-left: 1px solid var(--ion-color-lighter-shade);
}

.input-box {
  border-top: 0px solid var(--ion-color-lighter-shade);
  border-right: 0px solid var(--ion-color-lighter-shade);
  border-bottom: 0px solid var(--ion-color-dark-contrast-rgb);
  border-left: 0px solid var(--ion-color-lighter-shade);
  border-radius: 0px;
  font-size: 1rem;
  font-weight: 200;
  --background: transparent;
  line-height: 25px;
  --cursor: pointer;
}

.input-box ::placeholder {
  color: var(--ion-color-lighter-contrast);
  font-size: 1rem;
  font-weight: 200;
}

.language-btn {
  color: var(--ion-color-lighter-contrast);
  font-size: 0.875rem;
  font-weight: 200;
}

.input-box .ng-invalid.ng-touched {
  --placeholder-color: var(--ion-color-primary);
}

.border-left-grey {
  border-left: 1px solid var(--ion-color-lighter);
}
.booking-reason .mat-form-field-infix {
  top: -17px;
  position: relative;
}

.light-icon {
  font-size: 27px;
  align-items: center;
  color: var(--ion-color-light);
  padding: 3px 13px 2px 0px;
}

.color-icon {
  font-size: 27px;
  align-items: center;
  color: var(--ion-color-primary);
  padding: 3px 13px 2px 0px;
}
.color-dark {
  color: var(--ion-color-dark);
}
.med-input .myInput:hover,
.med-input .myInput:focus {
  color: var(--ion-color-dark-shade);
  box-shadow: 0 1px 4px var(--ion-color-primary-tint);
}
.appointment-headings {
  color: var(--ion-color-dark-shade);
  font-size: 1rem;
  font-weight: 400;
  font-family: ApaxThree;
}
.appointment-details {
  color: var(--ion-color-dark-shade);
  font-size: 1rem;
  font-weight: 200;
  font-family: ApaxThree;
}

.searchButton[disabled] {
  --background: var(--ion-color-primary);
  opacity: 0.5;
}

.searchButton ion-icon {
  left: 0px;
  position: absolute;
  padding: 0px;
  height: 30px;
  width: 30px;
}
.service-location-search-block {
  padding: 0;
  border: 2px solid var(--ion-color-primary);
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
}

div ion-calendar ion-calendar-month {
  border: 1px solid var(--ion-color-lighter-shade);
}
.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ion-page {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100vh;
}
.med-input .myInput ::placeholder {
  color: var(--ion-color-light-rgb);
  font-size: 16px;
  font-weight: 400;
}

.med-input .myInput .ng-invalid.ng-touched {
  --placeholder-color: var(--ion-color-primary);
}

.med-input .mat-form-field-label-wrapper .mat-form-field-label {
  position: initial;
}

.flex-container {
  display: flex;
  align-items: center;
}

.flex-item {
  flex-grow: 1;
}

.flex-item-button {
  margin-left: 10px;
}

body {
  font-family: --ion-font-family;
  --ion-toolbar-color: var(--white);
  --ion-background-color: var(--white);
  --ion-text-color: var(--dark-grey);
  --ion-text-color-rgb: var(--dark-grey);
  --ion-toolbar-background: var(--white);
  --ion-color-step-600: var(--text-dark);

  //The changes below force the screen to act as mobile if window size is larger than mobile
  @media only screen and (min-width: 600px) {
    background-color: transparent;
  }

  hyphens: none;

  li {
    color: var(--ion-color-dark);
    font-size: 1rem;
  }
}
ion-calendar {
  .days {
    height: 50px;

    button.days-btn {
      border-radius: 8px;
    }
  }
  .days-box .days .days-btn.today:after {
    height: 2px;
    width: 16px;
    background-color: var(--ion-color-primary);
    border-radius: 0px;
    bottom: 6px;
  }
  .days-box .days .days-btn.today p,
  .days-box .days .days-btn:not(.days-btn[disabled]) p,
  .days-box .days .days-btn:not(.days-btn[disabled]) .pager .active {
    color: var(--ion-color-primary);
  }

  .days-box .days .days-btn.next-month-day p {
    color: var(--ion-color-light);
  }
  .days-box .days .days-btn:not(.days-btn[disabled]) {
    border: 1px solid var(--ion-color-primary);
    background-color: var(--ion-color-primary-contrast);
    &:hover {
      background-color: var(--ion-color-primary);
      border: 1px solid var(--ion-color-primary);
      p {
        color: var(--ion-color-primary-contrast);
      }
    }
  }
}

.label-size {
  font-size: 1rem;
  font-weight: 200;
}

ion-item {
  font-family: --ion-font-family;
}
ion-text {
  font-weight: 400;
  font-style: normal;
  font-family: ApaxThree;
}

//Ionic components styles
ion-text {
  font-size: var(--ion-small-font-size);
}

ion-input.primary-btn input::placeholder {
  color: var(--ion-color-primary-contrast) !important;
  font-weight: 300 !important;
  text-align: center;
  opacity: 1 !important;
}
ion-input.primary-btn input {
  color: var(--ion-color-primary-contrast) !important;
  font-weight: 300 !important;
  text-align: center !important;
  opacity: 1 !important;
}
ion-input.primary-btn input:hover {
  cursor: pointer !important;
}
ion-card-title {
  --color: var(--ion-color-primary);
  text-align: center;
  font-size: 1.5rem;
  font-weight: 200;
  margin-bottom: 1.5rem;
}

ion-breadcrumb {
  color: var(--ion-color-light);
  font-size: var(--ion-small-font-size);
}
ion-breadcrumb.active {
  color: var(--ion-color-primary);
}

ion-header {
  background-color: var(--ion-color-primary-contrast);
  padding: 0;
  margin-bottom: 0;

  &.header-md::after {
    background-image: none;
    background-image: none;
  }

  ion-title {
    text-align: center;
    font-weight: 300;
    font-size: var(--ion-medium-font-size);
    text-transform: capitalize;
  }
}

ion-col {
  padding: 6px;
}

ion-footer {
  background-color: var(--ion-color-primary-contrast);
}

.container {
  max-width: 1140px;
  padding-right: var(--ion-padding, 0.75rem);
  padding-left: var(--ion-padding, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}
#app-header ion-button {
  --background: transparent;
  --background-hover: rgb(0, 0, 0, 0);
  --background-activated: transparent;
  --background-focused: transparent;
  --color: var(--ion-color-dark-shade);

  --border-radius: 0;
  --border-color: transparent;
  --border-style: solid;
  --border-width: 0px;

  --box-shadow: 0 2px 6px 0 rgb(0, 0, 0, 0);

  --ripple-color: transparent;

  --padding-top: 10px;
  --padding-bottom: 10px;
}

ion-button {
  text-transform: initial;
  min-height: 30px;
  font-size: var(--ion-small-font-size);
}

ion-button:focus {
  outline: none;
}

ion-slide {
  width: auto;
}

swiper-slide {
  width: auto;
}

input {
  &:focus {
    outline: none;
  }
}

ion-button > div {
  width: 100%;
  text-align: center;
  margin-right: 20px;
}
ion-button.button-clear.ion-activated {
  opacity: 1;
}

ion-icon {
  //This should remain static and not change per variable
  font-size: 32px;
}

mat-form-field {
  width: 100%;
  height: 100%;
}

ion-header.welcome,
ion-header.contactus,
ion-header.feedback {
  &.tertiary {
    ion-toolbar {
      --background: var(--ion-color-tertiary);
    }
  }

  ion-toolbar {
    --color: var(--ion-color-primary);

    ion-button {
      font-size: 12pt;
      font-weight: bold;

      ion-icon {
        font-size: 18pt;
      }
    }
  }
}

.title-holder h2 {
  margin: 0 0 5px;
}
ion-segment {
  min-height: auto;
  background-color: transparent;
  padding: 0px 19px 0px 19px;
  min-width: 100%;
}

ion-segment-button {
  --background-checked: #fefefe;
  --indicator-height: 100%;
  --indicator-color: var(--ion-color-tertiary);
  --indicator-box-shadow: none;
  --border-radius: 100px;
  border: solid 1px #ebebeb;
  margin: 2px;

  &::before {
    border-left: 0px;
    border-right: 0px;
  }

  &::part(indicator) {
    padding: 0px;
  }
}
.ion-page {
  background-color: var(--ion-color-primary-contrast);
}

.text-center {
  text-align: center;
}

.pager-hide {
  .swiper-pagination {
    display: none;
  }
}

.paper-stack {
  position: relative;
  //-webkit-transform: translate3d(0, 0, 0);

  &,
  &::before,
  &::after {
    box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.123);
    border-radius: 2px;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    height: 95%;
    width: 100%;
    background-color: var(--ion-color-dark);
  }

  &::after {
    top: -2px;
    right: -2px;
    transform: rotate(1.4deg);
    z-index: 0;
    opacity: 0.2;
    html.web & {
      opacity: 0.1;
    }
  }

  &::before {
    right: 1px;
    top: 2px;
    transform: rotate(-2.4deg);
    z-index: 0;
    opacity: 0.5;
    html.web & {
      opacity: 0.3;
    }
  }
}

.hide {
  display: none;
}

ion-modal.modal-default:not(.overlay-hidden) ~ ion-modal.modal-default {
  --backdrop-opacity: 0;
  clip-path: inset(-100vh -100vw 0px -100vw);
  --box-shadow: 0px 0px 5px 1px rgba(43, 39, 189, 0.5);
}

ion-accordion-group {
  ion-accordion {
    .ion-accordion-toggle-icon {
      html.web & {
        color: var(--ion-color-primary);
      }
      font-size: 20px;
      color: #646464;
    }
  }
}

ion-accordion.accordion-expanding > [slot="header"] .ion-accordion-toggle-icon,
ion-accordion.accordion-expanded > [slot="header"] .ion-accordion-toggle-icon {
  html[dir="ltr"] & {
    transform: rotate(90deg);
  }
}

ion-accordion.accordionFAQ.accordion-expanding > [slot="header"] .ion-accordion-toggle-icon,
ion-accordion.accordionFAQ.accordion-expanded > [slot="header"] .ion-accordion-toggle-icon {
  html.web[dir="ltr"] & {
    transform: rotate(0deg);
  }

  html.app[dir="ltr"] & {
    color: white;
    transform: rotate(90deg);
  }
}

ion-accordion.accordionFAQ.accordion-expanding,
ion-accordion.accordionFAQ.accordion-expanded {
  .header {
    div {
      .search-highlight {
        html.app & {
          color: white;
        }
      }
    }
  }
}

.hover-text::-webkit-scrollbar {
  display: none;
}

.hover-text {
  position: fixed;
  border-radius: 10px;
  padding: 10px;
  overflow-y: scroll;
  background-color: white;
  -ms-overflow-style: none;
  scrollbar-width: none;
  text-align: center;
  min-width: 100px;
}

.bg-primary {
  background-color: var(--ion-color-primary);
}

.rounded {
  border-radius: 8px;
}

.text-right {
  text-align: right;
}

ion-card-subtitle {
  font-size: 0.75rem;
}

.md-card {
  background-color: var(--ion-color-primary-contrast);
  border-radius: var(--ion-border-radius);
}

ion-toggle {
  --background: var(--ion-color-primary-contrast);
  border: 1px solid var(--ion-color-light);
  border-radius: 5px;
}

html[dir="ltr"] {
  .md-card-heading {
    text-align: left;
  }

  .float-right {
    float: right;
  }

  .float-left {
    float: left;
  }
}

.content-card {
  padding: 0px 3px 3px;
}

.title {
  color: #000;
  font-size: 1rem;
}

.subtitle {
  font-size: large;
  margin-bottom: 1rem;
}

.time {
  margin-top: 2rem;
  color: var(--ion-color-primary);
  font-size: 2rem;
}

.date {
  margin-top: 2rem;
  color: var(--ion-color-light-rgb);
  font-size: 1rem;
}

.logo-resize-web {
  max-width: 15rem;
  max-height: 5rem;
  padding-left: 4px;
  padding-right: 4px;
  margin: 5px;
  object-fit: contain;
  display: block;
  align-self: center;

  @media (max-width: 767px) {
    max-width: 15rem;
  }

  @media (max-width: 361px) {
    max-width: 10rem;
  }
}

/* Hide the icon by default */
.language-icon {
  color: var(--ion-color-primary);
}

.date-of-birth-btn::part(native) {
  padding: 10px !important;
  width: 100% !important;
  border: 1px solid var(--ion-color-lighter-shade) !important;
  --border-radius: 0.725rem !important;
  --padding-start: 1.25rem !important;
  --padding-end: 0.625rem !important;
  min-height: 3.4375rem !important;
}

/* Media query for screens less than 768px wide */
@media (max-width: 768px) {
  .language-btn {
    display: none; /* Hide the text */
  }
  .language-icon {
    display: block; /* Show the icon */
  }
}

ion-button {
  transition: filter 0.3s ease;
}

.swal-title,
.swal-modal,
.swal-button-container,
.swal-button {
  font-family: var(--ion-font-family);
}
.swal-button.swal-button--confirm {
  font-size: 1rem;
  height: 3rem;
  border-radius: 5px;
  margin-top: 2rem;
  padding-left: 3rem;
  padding-right: 3rem;
  cursor: pointer;
  border: 1px solid var(--ion-color-primary);
}
.logo-container {
  top: 10px;
}

@media (max-width: 500px) {
  .time {
    font-size: 1rem;
  }
}

/* Margin Utilities */
.m-0 {
  margin: 0 !important;
}
.m-1 {
  margin: 0.25rem !important;
} /* 4px if base font-size is 16px */
.m-2 {
  margin: 0.5rem !important;
} /* 8px */
.m-3 {
  margin: 0.75rem !important;
} /* 12px */
.m-4 {
  margin: 1rem !important;
} /* 16px */
.m-5 {
  margin: 1.25rem !important;
} /* 20px */
.m-6 {
  margin: 1.5rem !important;
} /* 24px */
.m-8 {
  margin: 2rem !important;
} /* 32px */
.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

/* Margin Top Utilities */
.mt-1 {
  margin-top: 0.25rem !important;
}
.mt-2 {
  margin-top: 0.5rem !important;
}
.mt-3 {
  margin-top: 0.75rem !important;
}
.mt-4 {
  margin-top: 1rem !important;
}
.mt-5 {
  margin-top: 1.25rem !important;
}
.mt-6 {
  margin-top: 1.5rem !important;
}
.mt-8 {
  margin-top: 2rem !important;
}
.mt-9 {
  margin-top: 4rem !important;
}
.mt-10 {
  margin-top: 6rem !important;
}

/* Margin Right Utilities */
.mr-1 {
  margin-right: 0.25rem !important;
}
.mr-2 {
  margin-right: 0.5rem !important;
}
.mr-3 {
  margin-right: 0.75rem !important;
}
.mr-4 {
  margin-right: 1rem !important;
}
.mr-5 {
  margin-right: 1.25rem !important;
}
.mr-6 {
  margin-right: 1.5rem !important;
}
.mr-8 {
  margin-right: 2rem !important;
}

/* Margin Bottom Utilities */
.mb-1 {
  margin-bottom: 0.25rem !important;
}
.mb-2 {
  margin-bottom: 0.5rem !important;
}
.mb-3 {
  margin-bottom: 0.75rem !important;
}
.mb-4 {
  margin-bottom: 1rem !important;
}
.mb-5 {
  margin-bottom: 1.25rem !important;
}
.mb-6 {
  margin-bottom: 1.5rem !important;
}
.mb-8 {
  margin-bottom: 2rem !important;
}
.mb-10 {
  margin-bottom: 4rem !important;
}

/* Margin Left Utilities */
.ml-1 {
  margin-left: 0.25rem !important;
}
.ml-2 {
  margin-left: 0.5rem !important;
}
.ml-3 {
  margin-left: 0.75rem !important;
}
.ml-4 {
  margin-left: 1rem !important;
}
.ml-5 {
  margin-left: 1.25rem !important;
}
.ml-6 {
  margin-left: 1.5rem !important;
}
.ml-8 {
  margin-left: 2rem !important;
}

/* Padding Top Utilities */
.pt-1 {
  padding-top: 0.25rem !important;
}
.pt-2 {
  padding-top: 0.5rem !important;
}
.pt-3 {
  padding-top: 0.75rem !important;
}
.pt-4 {
  padding-top: 1rem !important;
}
.pt-5 {
  padding-top: 1.25rem !important;
}
.pt-6 {
  padding-top: 1.5rem !important;
}
.pt-8 {
  padding-top: 2rem !important;
}

/* Padding Right Utilities */
.pr-1 {
  padding-right: 0.25rem !important;
}
.pr-2 {
  padding-right: 0.5rem !important;
}
.pr-3 {
  padding-right: 0.75rem !important;
}
.pr-4 {
  padding-right: 1rem !important;
}
.pr-5 {
  padding-right: 1.25rem !important;
}
.pr-6 {
  padding-right: 1.5rem !important;
}
.pr-8 {
  padding-right: 2rem !important;
}

/* Padding Bottom Utilities */
.pb-1 {
  padding-bottom: 0.25rem !important;
}
.pb-2 {
  padding-bottom: 0.5rem !important;
}
.pb-3 {
  padding-bottom: 0.75rem !important;
}
.pb-4 {
  padding-bottom: 1rem !important;
}
.pb-5 {
  padding-bottom: 1.25rem !important;
}
.pb-6 {
  padding-bottom: 1.5rem !important;
}
.pb-8 {
  padding-bottom: 2rem !important;
}
.pb-9 {
  padding-bottom: 4rem !important;
}
/* Padding Left Utilities */
.pl-1 {
  padding-left: 0.25rem !important;
}
.pl-2 {
  padding-left: 0.5rem !important;
}
.pl-3 {
  padding-left: 0.75rem !important;
}
.pl-4 {
  padding-left: 1rem !important;
}
.pl-5 {
  padding-left: 1.25rem !important;
}
.pl-6 {
  padding-left: 1.5rem !important;
}
.pl-8 {
  padding-left: 2rem !important;
}
/* Padding Left Utilities */
.p-0 {
  padding: 0 !important;
}
.p-1 {
  padding: 0.25rem !important;
}
.p-2 {
  padding: 0.5rem !important;
}
.p-3 {
  padding: 0.75rem !important;
}
.p-4 {
  padding: 1rem !important;
}
.p-5 {
  padding: 1.25rem !important;
}
.p-6 {
  padding: 1.5rem !important;
}
.p-8 {
  padding: 2rem !important;
}

/* Horizontal Margin Utilities */
.mx-1 {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}
.mx-2 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}
.mx-3 {
  margin-left: 0.75rem !important;
  margin-right: 0.75rem !important;
}
.mx-4 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}
.mx-5 {
  margin-left: 1.25rem !important;
  margin-right: 1.25rem !important;
}
.mx-6 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}
.mx-8 {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}

/* Vertical Margin Utilities */
.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}
.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}
.my-3 {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}
.my-4 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}
.my-5 {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}
.my-6 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}
.my-8 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

/* Horizontal Padding Utilities */
.px-1 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}
.px-2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}
.px-3 {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}
.px-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
.px-5 {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}
.px-6 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
.px-8 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

/* Vertical Padding Utilities */
.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}
.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
.py-3 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}
.py-4 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
.py-5 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}
.py-6 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}
.py-8 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

/* Container with a max-width and some horizontal margin */
.justified-right {
  flex: auto;
  display: flex;
  justify-content: right;
}

/* For various breakpoints, adjust the max-width */
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

/* Container Fluid for full width at all breakpoints */
.container-fluid {
  width: 100%;
  padding-right: var(--ion-padding, 0rem);
  padding-left: var(--ion-padding, 0rem);
  margin-right: auto;
  margin-left: auto;
}
@media (max-width: 575.98px) {
  .d-sm-none {
    display: none;
  }
}

/* Hide on screens smaller than 768px */
@media (max-width: 767.98px) {
  .d-md-none {
    display: none;
  }
}

/* Hide on screens smaller than 992px */
@media (max-width: 991.98px) {
  .d-lg-none {
    display: none;
  }
}

/* Hide on screens smaller than 1200px */
@media (max-width: 1199.98px) {
  .d-xl-none {
    display: none;
  }
}

.map {
  height: 480px;
}
.list-container {
  max-height: 500px;
  overflow-y: scroll;
  margin-top: 6px;
  background-color: var(--ion-color-primary-contrast);
}
.location-search-bar {
  height: 45px;
  @media (max-width: 768px) {
    display: block;
  }
}
.search-input {
  @media (max-width: 768px) {
    width: 100% !important;
    display: block;
  }
}
@media (max-width: 768px) {
  .search-text {
    display: none;
  }
  .search-icon {
    display: block;
  }
  .mobile-margin {
    margin-top: 8rem !important;
  }
}
@media (min-width: 768px) {
  .search-text {
    display: none;
  }
  .search-icon {
    display: block;
  }
}
.search-inputs {
  flex-grow: 1;
}
.search-btn-col {
  flex-grow: 0;
}

.service-location-page ion-col {
  padding: 0px;
}

ion-content::part(scroll) {
  scrollbar-width: thin;
  scrollbar-color: lightgray white;
}

.med-input input.mat-input-element {
  top: -9px;
  position: relative;
}
.branch-results {
  .record-name {
    font-weight: 400;
    color: var(--ion-color-primary);
    font-size: 1rem;
  }
  .record-district,
  .record-address-1,
  .record-address-2,
  .record-city {
    font-weight: 200;
    font-size: 0.75rem;
    text-transform: capitalize;
  }

  ion-button {
    text-transform: initial;
    min-height: 30px;
    --border-radius: 5px;
    font-size: var(--ion-extra-small-font-size);

    box-shadow: none;
  }
}

.service-location-page {
  width: 100%;
  margin: 0 auto;
}
.center-content {
  width: 100%;
  margin: 0 auto;
}
.bg-tertiary {
  background: var(--ion-color-tertiary);
}
.size-70 {
  font-size: 6rem;
  color: var(--ion-color-secondary);
}
.round-radio {
  --border-radius: 12px;
}

.service-location-page ion-label {
  font-family: var(--ion-font-family);
  font-size: var(--ion-large-font-size);
  font-weight: 200;
}
.no-bg {
  background-color: transparent;
}

/* TIMESLOT COMPONENT */

/* TIMESLOT CSS */

/** STEPPER CSS*/

.stepper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  .step {
    display: flex;
    align-items: center;

    .icon-container {
      position: relative;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin-right: 0.5rem;

      .step-index {
        font-size: 0.75rem;
        font-weight: normal;
        position: absolute;
        color: var(--ion-color-primary-contrast);
      }
    }

    .step-label {
      font-weight: 200;
      color: var(--ion-color-dark);
    }

    .step-separator {
      font-size: 2rem;
      margin: 0 1rem;
      transform: rotate(0deg);
    }
  }

  @media (max-width: 995px) {
    display: none;
    position: absolute;
  }
}

.mobile-stepper {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  box-shadow: unset;
  position: absolute;

  @media (max-width: 995px) {
    display: block;
  }

  .toolbar-title {
    white-space: normal;
    padding: 1rem 2rem;
  }
}

.mobile-stepper-base {
  @media (max-width: 995px) {
    margin-bottom: 3rem;
  }
}

//* booking time slot component */

.booking-details-container {
  width: 100%;
  text-align: center;
  color: var(--ion-color-dark);
  font-weight: 200;
}

ion-input {
  font-weight: 200;
}
ion-radio-group ion-label {
  font-weight: 200;
}
ion-textarea {
  font-weight: 200;
}

.checkbox-group ion-label {
  font-weight: 200;
}
:host-context([dir="rtl"]) {
  direction: ltr;
}

[dir="rtl"] {
  direction: ltr;
}

ion-searchbar .sc-ion-searchbar-md-h,
ion-searchbar .searchbar-input.sc-ion-searchbar-md,
ion-searchbar .searchbar-input.sc-ion-searchbar-ios {
  padding-left: 3rem;
  box-shadow: none !important;
  --border-radius: 0; /* Removes rounding of corners */
  --background: #ffffff; /* Sets a solid background color */
  --border-style: solid; /* Ensures the border is solid */
  --border-width: 1px; /* Standard border width */
  --border-color: black; /* Makes the border color crisp and visible */
  --box-shadow: none; /* Removes any shadow effects */
}
.no-shadow {
  box-shadow: none !important;
}

.powered-by-footer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: var(--ion-color-primary-contrast);
  z-index: 1000;
  @media (max-width: 768px) {
    padding-bottom: 50px;
  }
}

.powered-by-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
}

.powered-by-logo {
  max-height: 2.5rem;
}

.book-now-btn {
  width: 100%;
  font-size: small;
  top: 30%;
  position: relative;
}

.book-date {
  max-width: 50%;
  min-width: 20rem;
  border: var(--ion-color-light) 1px solid;
  margin-top: 1rem;
  padding: 1rem;
}

.color-primary {
  color: var(--ion-color-primary);
}

.color-secondary {
  color: var(--ion-color-secondary);
}

.color-tertiary {
  color: var(--ion-color-tertiary);
}
.color-dark {
  color: var(--ion-color-dark);
}

.grid {
  display: grid;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
}

.minimal-button {
  --background: transparent;
  --background-activated: transparent;
  --background-focused: transparent;
  --background-hover: transparent;
  --border-radius: 0px;
  --border-style: none;
  --box-shadow: none;
  --color: var(--ion-color-primary);
  --color-activated: var(--ion-color-primary-bright);
  --color-focused: var(--ion-color-primary-bright);
  --color-hover: var(--ion-color-primary-bright);
}

.left-align {
  justify-self: left;
}

.right-align {
  justify-self: right;
}

.full-width {
  width: 100%;
}
.slot-button:hover {
  filter: contrast(1.5);
}
.slot-button {
  width: 100%;
  --background: var(--ion-color-lighter) !important;
  --border-radius: 5px !important;
  --color: var(--ion-color-dark) !important;
  font-weight: 200;
}
.slot-heading {
  font-size: 1.2rem !important;
  --color: var(--ion-color-dark) !important;
}
.no-slots-found {
  font-size: 0.75rem !important;
  --color: var(--ion-color-dark) !important;
  font-weight: 200;
}

.radio-label {
  margin-left: 1rem;
}

.booking-details {
  max-width: 70%;
  min-width: 15rem;

  @media (max-width: 1024px) {
    max-width: 90%;
  }

  @media (max-width: 768px) {
    max-width: 90%;
  }
}

ion-datetime.book-date-calendar::part(month-year-button) {
  pointer-events: none;
}

.service-dateinput {
  padding: 5px;
  background: var(--ion-color-primary-contrast);
  border-radius: 5px;
  margin-left: 20px;
  margin-right: 25px;
  margin-top: 7px;
  font-size: 0.85rem !important;
}
ion-datetime-button.service-dateinput::part(month-year-button) {
  font-weight: 200 !important;
  --font-weight: 200 !important;
}

.details-grid {
  width: 70%;
  display: grid;
  gap: 16px;
  margin-top: 24px;
  margin: auto;

  @media (max-width: 767px) {
    width: 100%;
  }
}

.details-label {
  margin-bottom: 8px;
}

.image-main {
  font-size: 6rem;
  margin-bottom: 16px;
}

ion-footer {
  ion-toolbar {
    --background: var(--ion-background-color);
  }
}

.modal-cancel-btn {
  color: var(--white);
}

.swal2-container .swal2-popup {
  font-family: "ApaxThree", sans-serif;
}
.swal2-title {
  color: var(--ion-color-primary);
}
.swal2-html-container {
  margin: 0;
  color: var(--ion-color-primary);
}

.button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.minimal-button {
  flex: 0 0 auto;
}

.pad-size {
  padding: 0 20%;
  @media (max-width: 768px) {
    padding: 0;
  }
}

.make-a-booking-btn {
  margin: 0 auto;
  max-width: 22rem;
  border-radius: 5px;
  box-shadow: none;
  --background: var(--ion-color-alternate) !important;
  --color: var(--ion-color-primary) !important;
  --background-hover: var(--ion-color-primary);
  font-weight: 300 !important;
  min-width: 6rem;
  outline: none;
  border: none;
}

.map-container {
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  flex: 1;
  min-height: 500px;
  height: 100%;
  padding: 0;
  box-shadow: none;
  border: none;
}

.map {
  width: 100%;
  height: 100vh;
}

.vh-100 {
  height: 100vh;
}
.search-inputs {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#service-input {
  height: 45px !important;
}
.search-input {
  flex: 1;
  margin-right: 9px;
}
.search-input.reason {
  --background: transparent;
  height: 45px !important;
  cursor: pointer;
}
.search-input.local {
  margin: 0 31px 0px 22px;
  width: 60%;
  --border-radius: 5px;
  height: 45px !important;
  --min-height: 45px !important;
}
.select-radius {
  --border-radius: 5px;
  margin-left: 16px;
  margin-top: 7px;
  font-weight: 400;
  font-size: 0.85rem;
  max-width: unset !important;
  width: 100% !important;
  --min-height: 40px !important;
  height: 40px !important;
}

ion-select.select-dropdown {
  color: var(--ion-color-dark);
  min-height: 40px !important;
  height: 40px !important;
  margin: 0px 0px 0px 35%;
}
#service-input.sc-ion-input-md-h:not(.legacy-input) {
  min-height: 45px !important;
}

.mw-800 {
  max-width: 800px !important;
}
.input-box {
  border-top: 0px solid var(--ion-color-lighter-shade);
  border-right: 0px solid var(--ion-color-lighter-shade);
  border-bottom: 0px solid var(--ion-color-dark-contrast-rgb);
  border-left: 0px solid var(--ion-color-lighter-shade);
  border-radius: 0px;
  font-size: 1rem;
  font-weight: 200;
  --background: transparent;
  line-height: 25px;
  --cursor: pointer;
}

.fullscreen-map-landing {
  height: 70vh;
  position: relative;
  width: 100vw;
}
.fullscreen-map {
  height: 87vh;
  position: relative;
  width: 100vw;
  @media (max-width: 768px) {
    height: 74vh;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 3;
  background-position: center;
  background-size: cover;
}

.helper-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 28px;
  font-weight: 600;
  z-index: 4;
  text-align: center;
}

@keyframes shimmer {
  0% {
    border-color: var(--ion-color-primary);
    box-shadow: 0 0 5px var(--ion-color-primary);
  }
  50% {
    border-color: var(--ion-color-primary-bright);
    box-shadow: 0 0 15px var(--ion-color-primary-bright);
  }
  100% {
    border-color: var(--ion-color-primary);
    box-shadow: 0 0 5px var(--ion-color-primary);
  }
}

.button-shimmer {
  border: 2px solid var(--ion-color-primary);
  animation: shimmer 2s infinite;
}
body.swal2-shown.swal2-height-auto {
  height: inherit !important;
}

.location-icon {
  font-size: 3rem;
  margin-bottom: 15px;
  color: var(--ion-color-primary) !important;
}
.location-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 300;
  margin-top: 20px;
}

.store-header {
  box-shadow: none;
}

.store-title {
  font-size: 1.5rem;
  font-weight: 400;
  color: var(--ion-color-primary-shade);
  text-align: left;
}
.app-store-info {
  border-radius: 20px;
}
.store-close {
  --color: #000000;
  font-size: 1.5rem;
}

.store-content {
  padding: 0px 16px 16px 16px;
}

.store-content-row {
  padding: 0px 16px 16px 16px;
}

.store-content-col {
  padding: 0px 16px 16px 16px;
}

.store-content-col ion-label p {
  font-size: 1rem;
  color: var(--ion-color-dark);
  margin: 4px 0;
}
.store-modal {
  --border-radius: 25px;
  overflow: hidden;
}

// Drawer menu

.drawer {
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  max-width: 40%;
  min-width: 15rem;
  height: 100%;
  background: var(--ion-color-background);
  transform: translateX(-100%);
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;

  &.is-open {
    transform: translateX(0);
  }
}

.drawer-content {
  overflow-y: auto;
  flex-grow: 1;
  padding: 10px 0px 10px 5px;
  z-index: 11;
  background: var(--ion-color-background);
}

.toggle-button {
  position: absolute;
  right: -35px;
  top: 50%;
  cursor: pointer;
  background: var(--ion-color-primary);
  border: 1px solid var(--ion-color-primary);
  border-radius: 12px;
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ion-icon {
    margin-right: 10px;
    font-size: 20px;
    color: var(--ion-color-primary-contrast);
  }
}

/*formly css*/

.default-input.hydrated {
  --border-color: var(--ion-color-lighter-shade);
  --border-radius: 0.625rem !important; /* 10px to rem */
  --padding-start: 1.25rem !important; /* 20px to rem */
  --padding-end: 0.625rem !important; /* 10px to rem */
  min-height: 3.4375rem !important; /* 55px to rem */
}

.form-text {
  font-size: 0.875rem !important;
  font-weight: 200;
}

.divider-input {
  height: 0.0625rem; /* 1px to rem */
  border-bottom: 0.0625rem solid var(--ion-color-lighter-shade); /* 1px to rem */
  display: flex;
  width: 97%;
  margin: 1.25rem 0 2.5rem; /* 20px 0 40px to rem */
  justify-content: space-between;
}

.textarea-input .default-input {
  margin-bottom: 2.5rem; /* 40px to rem */
}

.date-label {
  margin: 1.5rem 0 1.5rem 0;
}

.label-input .label {
  margin-bottom: 1.25rem; /* 20px to rem */
}

.date-input formly-field-custom-date ion-col {
  margin: 1.875rem 0; /* 30px 0 30px to rem */
}

.input-input {
  margin: 1.25rem 0 2.5rem; /* 20px 0 40px to rem */
}
.checkbox-input {
  margin: 1.25rem 0 2.5rem; /* 20px 0 40px to rem */
}
.radio-input formly-field-custom-radio ion-col ion-item ion-label:first-child {
  margin-top: 1.875rem; /* 30px to rem */
}

.slider-label {
  margin: 1.5rem 0 0 0;
}

ion-range {
  --bar-background: var(--ion-color-lighter);
  --bar-background-active: var(--ion-color-secondary);
  --bar-height: 0.5rem; /* 8px to rem */
  --bar-border-radius: 0.5rem; /* 8px to rem */
  --knob-background: var(--ion-color-primary);
  --knob-size: 2.5rem; /* 40px to rem */
  --pin-background: var(--ion-color-primary);
  --pin-color: var(--ion-color-primary-contrast);
  margin-bottom: 1.4rem;
}

.checkbox-container {
  display: flex;
  align-items: center;
}

.checkbox-container .label {
  margin-left: 0.5rem;
}

.drawer-width {
  max-width: 275px !important;
}
.text-wrapper {
  text-overflow: initial !important;
  white-space: break-spaces !important;
  overflow: visible !important;
  padding-right: 0px !important;
}
ion-item.branch-results {
  cursor: pointer;
}

.center-form {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}
.payment-methods ion-segment {
  --background: transparent;
  --indicator-color: transparent;
}
.payment-methods .option-group {
  max-width: 200px;
  display: flex;
}
.payment-methods .option-group .label-placement {
  position: relative;
  top: 10px;
  margin-right: 20px;
  min-width: 70px;
  font-size: 0.75rem;
  margin-left: 16px;
  line-height: 0.875rem;
}

.payment-methods ion-segment-button {
  background: transparent;
  border-radius: 20px;
  border-width: 4px;
  border-color: var(--ion-color-primary);
  color: var(--ion-color-primary) !important;
  min-width: 70px !important;
  min-height: 30px !important;
  font-size: 14px;
  margin-right: 10px;
  text-align: center;
  line-height: 0.875rem;
  border: 2px solid;
}

.payment-methods ion-segment-button.segment-button-checked {
  background: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast) !important;
}

.payment-methods ion-segment-button:last-child {
  margin-right: 0;
}
.payment-methods ion-segment-button ion-label {
  margin: 0;
  padding: 0;
  font-size: 10px;
  line-height: 0.875rem;
}

.payment-methods ion-segment-button.segment-button-checked:hover {
  color: var(--ion-color-primary-contrast) !important;
}
ion-select.select-dropdown {
  color: var(--ion-color-dark);
  min-height: 40px !important;
  height: 40px !important;
  margin: 0px 0px 0px 35%;
}
#service-input.sc-ion-input-md-h:not(.legacy-input) {
  min-height: 45px !important;
}

.search-container .sc-ion-input-md-h:not(.legacy-input) {
  max-height: auto !important;
  min-height: auto !important;
}
.search-container {
  z-index: 888;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translateX(-50%);
  margin: 0 auto;
  border: 2px solid var(--ion-color-primary);
  line-height: 0;
  background-color: var(--ion-color-primary-contrast);
  width: 100%;
  max-width: 750px;
  border-radius: 0px;
  @media (max-width: 768px) {
    max-width: 350px !important;
    border: 0px solid var(--ion-color-primary);
    background-color: transparent;
    top: 30%;
  }
}

.search-form-inline {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  line-height: 0;
}

.search-inputs-inline {
  flex: 1;
  line-height: 0;
}

.search-input {
  --padding-start: 1rem !important;
  --padding-end: 0 !important;
  flex: 1;
  margin: auto;
  max-height: 48px;
  font-weight: var(--ion-font-weight-light);
  --color: var(--ion-color-dark);
  color: var(--ion-color-dark);
  --max-height: 48px;
  line-height: 0;
  opacity: 1 !important;
  display: flex;
  @media (max-width: 768px) {
    text-align: center;
  }
}
.search-input .select-label {
  position: relative;
  display: block;
  width: 100%;
  margin: 10px 0px 0px 8px;
  padding: 14px;
  font-size: 0.875rem !important;
  @media (max-width: 768px) {
    margin: 8px -25px 0px 8px;
  }
}
.find-label {
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
}

.primary-select .native-input::placeholder {
  --placeholder-color: var(--ion-color-dark);
  color: var(--ion-color-dark);
  opacity: 1 !important;
  font-size: 0.875rem !important;
}
.search-button {
  display: flex;
  width: 48px;
  height: 48px;
  border-radius: 0; /* Make the button square */
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
  margin: 0;
  max-height: 48px;
  --padding-start: 0 !important;
  --padding-end: 0 !important;
  line-height: 0;
  @media (max-width: 768px) {
    width: 100% !important;
  }
}

.search-button ion-icon {
  font-size: 1.5rem;
  @media (max-width: 768px) {
    display: none;
  }
}
.service-dateinput {
  margin: 0;
  padding: 0;
  display: flex;
  font-size: 0.875rem;
  margin-left: 20px;
  margin-right: 25px;
}
.date-select-btn {
  height: auto;
  border-radius: 0;
  background: transparent;
  --color: var(--ion-color-dark);
  color: var(--ion-color-dark);
  margin: 0;
  font-weight: var(--ion-font-weight-light);
  margin-right: 2rem;
  margin-top: 5px;
  font-size: 0.875rem;
  @media (max-width: 768px) {
    margin: 0 24%;
    padding: 8px 0 0 0;
  }
}
.date-select-icon {
  --color: var(--ion-color-primary);
  color: var(--ion-color-primary);
  margin-right: 1rem;
  font-size: 1.5rem;
  margin-top: 11px;
  cursor: pointer;
  @media (max-width: 768px) {
    padding: 0px 0px 0px 12px;
  }
}
.form-separator {
  margin-top: 10px;
  margin-right: 10px;
  padding: 0;
  @media (max-width: 768px) {
    display: none;
  }
}
.icon-group {
  display: flex;
  flex-direction: row;
  margin: auto;
}
.map-icon {
  font-size: 27px;
  align-items: center;
  color: var(--ion-color-primary);
  position: absolute;
  top: 10px;
  left: -10px;
  @media (max-width: 768px) {
    top: 8px;
    left: 9px;
  }
}
.chev-icon {
  font-size: 27px;
  align-items: center;
  color: var(--ion-color-primary);
  padding: 11px 5px 2px 0px;
  @media (max-width: 768px) {
    padding: 8px 9px 2px 0;
  }
}
.autocomplete-input-wrapper {
  padding: 1px 0px 0px 10px;
  background: transparent;
  border: none;
  font-size: 0.875rem;
}
.form-separator-date {
  margin-top: 10px;
  margin-right: 10px;
  padding: 0;
  @media (max-width: 768px) {
    display: none;
  }
}
.search-btn-col {
  max-width: 48px !important;
  width: 100% !important;
  @media (max-width: 768px) {
    max-width: 100% !important;
  }
}
.col-location {
  width: 100% !important;
  max-width: 205px !important;
  display: flex;
  flex-direction: row;
  @media (max-width: 768px) {
    max-width: 100% !important;
    background-color: var(--ion-color-primary-contrast);
    border: 2px solid var(--ion-color-primary);
    margin-bottom: 10px;
    height: 48px;
  }
}
.col-date {
  max-width: 220px !important;
  width: 100% !important;
  @media (max-width: 768px) {
    max-width: 100% !important;
    background-color: var(--ion-color-primary-contrast);
    border: 2px solid var(--ion-color-primary);
    margin-bottom: 10px;
    height: 48px;
  }
}
.col-reason {
  width: 100% !important;
  max-width: 275px !important;
  @media (max-width: 768px) {
    max-width: 100% !important;
    background-color: var(--ion-color-primary-contrast);
    border: 2px solid var(--ion-color-primary);
    margin-bottom: 10px;
    height: 48px;
  }
}

.flex-nowrap {
  flex-wrap: nowrap;
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
}
.center-all .ion-page {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.center-all {
  --width: 360px;
  --height: 360px;
}
.search-title {
  margin: 0 0 30px;
  font-size: 1.9rem;
  font-weight: 500;
  text-transform: capitalize;
  color: var(--ion-color-primary);
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -90px;
  line-height: 32px;
  min-width: 100%;
}
.selected-value {
  line-height: 1rem;
  margin-top: 1rem;
  margin-left: 1rem;
  text-overflow: ellipsis;
  overflow: hidden;
  text-wrap: nowrap;
  width: 100%;
  font-size: 0.875rem;
}
.autocomplete-input-wrapper {
  text-overflow: ellipsis;
  overflow: hidden;
  text-wrap: nowrap;
}
.moved-up {
  top: 4% !important;
  transition: top 0.3s ease;
}

.calendar-skeleton {
  width: 290px;
  height: 300px;
  display: flex;
  flex-direction: column;
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  padding: 4px;
}

.header-skeleton {
  width: 100%;
  height: 60px;
  margin: 0 2px;
}

.calendar-body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 10px 10px 0px 10px;
}

.calendar-row {
  display: flex;
  justify-content: left;
}

.day-skeleton {
  width: 30px;
  height: 36px;
  margin: 4px;
}

.custom-loader {
  --spinner-size: 80px;
  --width: 250px;
  --height: 250px;
  --border-radius: 15px;
  padding: 20px;
}

.custom-loader .loading-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-right: 40px;
}

.custom-loader .spinner-bubbles {
  height: 50px;
  width: 50px;
  padding: 20px;
  margin-bottom: 20px;
}

.language-radios:hover {
  --background: rgba(0, 0, 0, 0.05);
  cursor: pointer;
}
.language-radios input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  background-color: #f0f0f0;
  border: 2px solid #ddd;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: inline-block;
  position: relative;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.language-radios input[type="radio"]:checked {
  background-color: var(--ion-color-primary);
  border-color: var(--ion-color-primary);
}

.language-radios input[type="radio"]::before {
  content: "";
  position: absolute;
  top: 23%;
  left: 17%;
  width: 10px;

  height: 10px;
  background-color: var(--ion-color-primary-contrast);
  border-radius: 50%;
  transform: scale(0);

  transform-origin: center;
  transition: transform 0.2s ease;
}

.language-radios input[type="radio"]:checked::before {
  transform: scale(1);
}

.language-radios input[type="radio"]:hover {
  border-color: #999;
}
.filter-area {
  position: absolute;
  top: 80px;
  width: 88%;
}

.filter-icon {
  font-size: 24px;
  cursor: pointer;
  margin-right: 20px;
  position: relative;
  color: var(--ion-color-primary);
  top: 7px;
}
.back-btn {
  position: absolute;
  left: -20px;
  top: -3px;
}
.results-area {
  margin-top: 50px;
  padding-bottom: 50px;
}
.filter-text {
  margin-right: 30px;
  padding: 0px;
  margin-top: 4px;
  color: var(--ion-color-primary);
}

.range-filter {
  padding: 20px 0px 0px 0px;
}
.apply-btn {
  margin-top: 10px;
}
.filter-radius-text {
  text-align: center;
  display: flex;
  flex-direction: row;
  align-content: space-between;
  justify-content: space-between;
}

.filter-btn {
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: space-between;
  margin: 0px 0 0 18px;
  width: 100%;
  top: 26px;
  right: 10px;
  z-index: 10;
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.radius-box {
  border: 1px solid var(--ion-color-light);
  padding: 10px 47px;
  position: relative;
  top: -10px;
  right: -85px;
}
.store-info {
  text-transform: capitalize;
}
.transition-drawer {
  transition: transform 0.3s ease;
}
ion-label.availibility-link {
  max-width: 80px !important;
  text-wrap: wrap !important;
  overflow-wrap: initial !important;
  overflow: visible !important;
  font-size: 0.75rem !important;
  text-decoration: underline !important;
  text-align: center !important;
  color: var(--ion-color-secondary) !important;
  position: absolute;
  right: 10px;
  bottom: 0;
}

.text-mobile-center {
  @media screen and (max-width: 768px) {
    text-align: center !important;
  }
}
.pac-container:after {
  /* Disclaimer: not needed to show 'powered by Google' if also a Google Map is shown */

  background-image: none !important;
  height: 0px;
}
.pac-container:after {
  content: none !important;
}
.privacy-link {
  font-size: small;
  text-decoration: underline;
}
.broadcast-message-container-booking {
  z-index: 888;
  position: absolute;
  bottom: -2%;
  left: 50%;
  transform: translate(-50%);
  width: 90%;
  text-align: center;
  @media (max-height: 768px) {
    display: none !important;
  }
}
.broadcast-message-disclaimer {
  font-size: small;
  font-weight: 300;
  padding: 0.75rem;
  color: var(--ion-color-dark);
  text-align: center;
}
.broadcast-message-container {
  z-index: 888;
  position: absolute;
  bottom: 19%;
  left: 50%;
  transform: translate(-50%);
  width: 100%;
  font-size: small;
}
.broadcast-message {
  font-size: small;
  font-weight: 300;
  color: var(--ion-color-primary);
  max-width: 95%;
  width: 100%;
  text-align: center;
}
.broadcast-visible {
  display: block; /* Or flex if required */
  visibility: visible;
}

.d-none {
  display: none !important;
  visibility: hidden !important;
}
.alert-radio-icon {
  margin: 2px !important;
}
.alert-button-inner {
  margin: 2px !important;
}
.counter-container {
  margin: 1rem 0;
  text-align: center;

  .counter-label {
    display: block;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: bold;
    color: #333;
  }

  .counter-controls {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    .counter-btn {
      width: 2.5rem;
      height: 2.5rem;
      font-size: 1.5rem;
      padding: 0;
      text-align: center;
    }

    .counter-value {
      font-size: 1.5rem;
      font-weight: bold;
      min-width: 2rem;
      text-align: center;
    }
  }
}

.divider-line {
  border-bottom: 2px solid var(--ion-color-secondary);
}
.dropdown-label {
  color: var(--ion-color-primary);
  p {
    font-size: 11px;
    font-weight: 300;
  }
}

.booking-label {
  color: var(--ion-color-primary);
}
.booking-label-col {
  text-align: center;
  margin: 15px 0px;
}
.multi-cols {
  height: 60px;
}

.group-size-container {
  position: fixed;
  z-index: 999;
  background: white;
  width: 100%;
  top: 130px;
  padding: 15px 10px 0px 10px;
  right: 0px;
}
.pac-container {
  width: 330px !important;
  max-width: 90% !important;

  z-index: 999999;
  @media (max-width: 768px) {
    left: 20% !important;
  }
}
.top-70 {
  top: 70px;
  position: relative;
}
.top-40 {
  top: 40px;
  position: relative;
}
